import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Editor from "../Components/Editor";

const OurVision = ({ icon }) => {

    const [data, setData] = useState([]);
    const getData = async () => {
        const { data } = await axios.get('/ourVision');
        setData(data);
    };

    const [selectedFile, setSelectedFile] = useState(null);
    const uploadChange = async (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await axios.post("/upload", formData);
        setSelectedFile(data);
    };

    useEffect(() => {
        if (data.length > 0) {
            if (!window.$.fn.dataTable.isDataTable('.dt-tbl')) {
                window.$('.dt-tbl').DataTable();
            }
        }
    }, [data]);

    const [content, setContent] = useState({});
    const handleContentChange = (name, value) => {
        setContent(prevContent => ({ ...prevContent, [name]: value }));
    }

    useEffect(() => {
        getData();
        $('.nav-item').removeClass('active');
        $('.nav-item').each(function () {
            if ($(this).find('a').attr('href') === window.location.pathname) {
                $(this).addClass('active');
            }
        });
    }, []);


    return (
        <div className="page-content">
            <nav className="page-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Main</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Our Vision</li>
                </ol>
            </nav>
            <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <h6 className="card-title">Our Vision</h6>
                                </div>
                            </div>
                            <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Description</th>
                                        <th>Option</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td><img src={item.image} /></td>
                                                <td>{item.description.length > 100 ? <div dangerouslySetInnerHTML={{ __html: item.description.slice(0, 100) + '...' }}></div> : item.description}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-outline-success mx-1" data-bs-toggle="modal" data-bs-target={`#edit${item.id}`}>
                                                        <icon.Edit size="18" />
                                                    </button>
                                                </td>
                                                <div className="modal fade" id={`edit${item.id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-lg">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Edit</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                            </div>
                                                            <form onSubmit={(e) => {
                                                                e.preventDefault();
                                                                const formData = new FormData(e.target);
                                                                formData.append('description', content.content ? content.content : item.description);
                                                                const data = Object.fromEntries(formData);
                                                                axios.put(`/ourVision/${item.id}`, data).then(() => {
                                                                    window.$(`#edit${item.id}`).modal('hide');
                                                                    toast.success('updated successfully');
                                                                    getData();
                                                                })
                                                            }} >
                                                                <div className="modal-body">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Description</label>
                                                                        <Editor name="description" targetTextareaId="content" defaultValue={item.description} onContentChange={(value) => handleContentChange("content", value)} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Background</label>
                                                                        <input type="file" className="form-control" onChange={uploadChange} accept="application/pdf, image/*" />
                                                                        <input type="hidden" name="image" defaultValue={selectedFile ? selectedFile : item.image} required />
                                                                        <img className="img-fluid rounded mt-2" src={`../../${item.image}`} alt={item.small_title} />
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">close</button>
                                                                    <button type="submit" className="btn btn-primary">update</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default OurVision;