import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import * as icon from 'react-feather';
import ErrorPage from './Error';
import APP from '../App';
import Login from '../Pages/Login';
import Products from '../Pages/Products';
import Users from '../Pages/Users';
import Social from '../Pages/Social';
import Slides from '../Pages/Slides';
import Settings from '../Pages/General';
import Clients from '../Pages/Clients';
import WhyUs from '../Pages/WhyUs';
import OurVision from '../Pages/OurVision';
import About from '../Pages/About';


const Routes = createBrowserRouter([
    {
        path: "/login", element:
            <div className="main-wrapper">
                <div className="page-wrapper full-page">
                    <Login icon={icon} />
                </div>
                .0
            </div>, errorElement: <ErrorPage />,
    },
    {
        path: "/",
        element: <APP><Products icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/users",
        element: <APP><Users icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/socials",
        element: <APP><Social icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/slides",
        element: <APP><Slides icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/settings",
        element: <APP><Settings icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/clients",
        element: <APP><Clients icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/products",
        element: <APP><Products icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/why-us",
        element: <APP><WhyUs icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/our-vision",
        element: <APP><OurVision icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/about-us",
        element: <APP><About icon={icon} /></APP>,
        errorElement: <ErrorPage />,
    },
]);

export default Routes;