import Sidebar from "./Layout/Sidebar";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import * as icon from 'react-feather';

const APP = ({ children }) => {
  return (
    <div className="main-wrapper">
      <Sidebar icon={icon} />
      <div className="page-wrapper">
        <Navbar icon={icon} />
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default APP;